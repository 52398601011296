import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null,
}

export const activeBusinessEntitySlice = createSlice({
  name: 'activeBusinessEntity',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  reducers: {
    // The PayloadAction type here is used to declare the contents of `action.payload`
    setValue: (state, action) => {
      if (action.payload === null) {
        let allBusinessEntities = localStorage.getItem('allBusinessEntities')
        if (allBusinessEntities !== null && allBusinessEntities !== 'undefined') {
          const publishedBEs = (JSON.parse(allBusinessEntities) || []).filter(
            (be) => be.publishedAt,
          )
          const firstBE = publishedBEs[0] || null
          if (firstBE) action.payload = firstBE
        }
      }

      state.value = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setValue } = activeBusinessEntitySlice.actions

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
export const selectActiveBusinessEntity = (state) => state.activeBusinessEntity.value

// exporting the reducer here, as we need to add this to the store
export default activeBusinessEntitySlice.reducer
