import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null,
}

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  reducers: {
    // The PayloadAction type here is used to declare the contents of `action.payload`
    setValue: (state, action) => {
      state.value = action.payload
    },
    updateCurrentUserSingleBusinessEntity: (state, action) => {
      const beList = state.value.businessEntities.data
      const beIdToBeUpdated = beList.findIndex((be) => be.id === action.payload.id)
      if (beIdToBeUpdated === -1) beList.push(action.payload)
      beList[beIdToBeUpdated] = action.payload
    },
    removeCurrentUserSingleBusinessEntity: (state, action) => {
      const beList = state.value.businessEntities.data
      const beIdToBeUpdated = beList.findIndex((be) => be.id === action.payload.id)
      beList.splice(beIdToBeUpdated, 1)
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setValue,
  updateCurrentUserSingleBusinessEntity,
  removeCurrentUserSingleBusinessEntity,
} = currentUserSlice.actions

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
export const selectCurrentUser = (state) => state.currentUser.value

// exporting the reducer here, as we need to add this to the store
export default currentUserSlice.reducer
