import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}

export const sidebarUnfoldableSlice = createSlice({
  name: 'sidebarUnfoldable',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  reducers: {
    // The PayloadAction type here is used to declare the contents of `action.payload`
    setValue: (state, action) => {
      state.value = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setValue } = sidebarUnfoldableSlice.actions

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
export const selectSidebarUnfoldable = (state) => state.sidebarUnfoldable.value

// exporting the reducer here, as we need to add this to the store
export default sidebarUnfoldableSlice.reducer
