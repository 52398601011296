import { createSlice } from '@reduxjs/toolkit'

const deleteEntries = (entriesToDelete, allEntries) => {
  return allEntries.filter((entry) => {
    for (let i = 0; i < entriesToDelete.length; ++i) {
      if (entry.id === entriesToDelete[i]) {
        entriesToDelete.splice(i, 1)
        return false
      }
    }
    return true
  })
}

const initialState = {
  value: { allChecklists: [], allChecklistItems: [], primaryChecklists: [], activeRowId: null },
}

export const taskChecklistSlice = createSlice({
  name: 'taskChecklist',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  reducers: {
    // The PayloadAction type here is used to declare the contents of `action.payload`
    setValue: (state, action) => {
      state.value = action.payload || initialState.value
    },
    setActiveRow: (state, action) => {
      state.value.activeRowId = action.payload
    },
    createFromTemplate: (state, action) => {
      const parentItemId = action.payload.parentItemId
      // add new checklists to checklists array
      state.value.allChecklists = state.value.allChecklists.concat(action.payload.newChecklists)
      // new checklist is child
      if (parentItemId) {
        // set parent item's child_checklist as new checklist id
        state.value.allChecklistItems[
          state.value.allChecklistItems.findIndex((item) => item.id === parentItemId)
        ].child_checklist = action.payload.newChecklists[0].id
        // add new items to items array
        state.value.allChecklistItems = state.value.allChecklistItems.concat(
          action.payload.newItems,
        )
        // new checklist is root
      } else {
        // add new items to items array
        state.value.allChecklistItems = state.value.allChecklistItems.concat(
          action.payload.newItems,
        )
        // add new checklist to root checklist
        state.value.primaryChecklists = state.value.primaryChecklists.concat(
          action.payload.newChecklists[0].id,
        )
      }
    },
    createItem: (state, action) => {
      // add new item to items array
      state.value.allChecklistItems.push(action.payload.item)
      // add new item id to parent checklist's order array
      state.value.allChecklists[
        state.value.allChecklists.findIndex(
          (checklist) => checklist.id === action.payload.checklistId,
        )
      ].order.push(action.payload.item.id)
    },
    createChecklist: (state, action) => {
      const payload = action.payload
      // add new checklist to checklists array
      state.value.allChecklists.push(payload.newChecklist)
      // new checklist is child
      if (payload?.parentItemId) {
        // set parent item's child_checklist as new checklist's id
        state.value.allChecklistItems[
          state.value.allChecklistItems.findIndex((item) => item.id === payload.parentItemId)
        ].child_checklist = payload.newChecklist.id
        // new checklist is root
      } else {
        // add new checklist to root checklists
        state.value.primaryChecklists.push(payload.newChecklist.id)
      }
    },
    updateItem: (state, action) => {
      const itemPos = state.value.allChecklistItems.findIndex(
        (item) => item.id === action.payload.itemId,
      )
      const [key, value] = Object.entries(action.payload.updatedField).flat()
      switch (key) {
        case 'is_checked': {
          state.value.allChecklistItems[itemPos].is_checked = value
          break
        }
        case 'description': {
          state.value.allChecklistItems[itemPos].description = value
          break
        }
        case 'deadline': {
          state.value.allChecklistItems[itemPos].deadline = value
          break
        }
        case 'assignee': {
          state.value.allChecklistItems[itemPos].assignee = value
          break
        }
      }
    },
    updateChecklist: (state, action) => {
      const checklistPos = state.value.allChecklists.findIndex(
        (checklist) => checklist.id === action.payload.checklistId,
      )
      for (const [key, value] of Object.entries(action.payload.updatedFields)) {
        switch (key) {
          case 'title': {
            state.value.allChecklists[checklistPos].title = value
            break
          }
          case 'description': {
            state.value.allChecklists[checklistPos].description = value
            break
          }
          case 'is_template': {
            state.value.allChecklists[checklistPos].is_template = value
            break
          }
        }
      }
    },
    deleteItem: (state, action) => {
      const parentChecklist = action.payload.parentChecklist
      const checklistsToDelete = action.payload.checklistsToDelete
      const checklistItemsToDelete = action.payload.checklistItemsToDelete
      state.value.allChecklists = deleteEntries(checklistsToDelete, state.value.allChecklists)
      state.value.allChecklistItems = deleteEntries(
        checklistItemsToDelete,
        state.value.allChecklistItems,
      )
      state.value.allChecklists[
        state.value.allChecklists.findIndex((checklist) => checklist.id === parentChecklist.id)
      ].order = parentChecklist.order
    },
    deleteChecklist: (state, action) => {
      const checklistId = action.payload.checklistId
      const checklistsToDelete = action.payload.checklistsToDelete
      const checklistItemsToDelete = action.payload.checklistItemsToDelete
      state.value.allChecklists = deleteEntries(checklistsToDelete, state.value.allChecklists)
      state.value.allChecklistItems = deleteEntries(
        checklistItemsToDelete,
        state.value.allChecklistItems,
      )
      if (action.payload.isRoot) {
        state.value.primaryChecklists.splice(
          state.value.primaryChecklists.findIndex((checklist) => checklist === checklistId),
          1,
        )
      } else {
        state.value.allChecklistItems[
          state.value.allChecklistItems.findIndex((item) => item.child_checklist === checklistId)
        ].child_checklist = null
      }
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setActiveRow,
  setValue,
  createFromTemplate,
  createItem,
  createChecklist,
  updateItem,
  updateChecklist,
  deleteItem,
  deleteChecklist,
} = taskChecklistSlice.actions

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
export const selecttaskChecklist = (state) => state.taskChecklist.value

// exporting the reducer here, as we need to add this to the store
export default taskChecklistSlice.reducer
